import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page introuvable" />
    <section className="Legal container">
      <h1 style={{ textAlign: `center` }}>Page introuvable</h1>
    </section>
  </Layout>
);

export default NotFoundPage;
